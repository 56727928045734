<template>


        <!--  <div class="pg-sidebar"> </div> -->

        <!-- <router-link  class="all-wrp-link" :to="{ name: 'matchDetail.scorecard', params: { slug: match.slug, id: match.id } }"> - match - </router-link> -->
        <div>
          <!-- <div class="pg-content"> -->
              <!-- <div class="cd_cr_list" v-for="(tournament, tour_key) in matches" :key="tour_key" > -->

                  <div class="cd_cr_hdg" v-if="grouped_matches.length">
                      <h2>
                          <router-link :to="{name: 'tournament_matches', params: {slug: grouped_matches[0].tournament.slug+'-'+grouped_matches[0].tournament.id} }">
                              <span style="color: #0c2251">
                                  {{ (($route.fullPath).indexOf('tournament')) > 0? ( _key.split(' ')[0]) : _key}}
                              </span>
                              <!-- Don't show in tournament matches -->
                              <span v-if="($route.fullPath).indexOf('tournament') < 0" style="color: #0c2251"> - </span>
                              <span v-if="($route.fullPath).indexOf('tournament') < 0">  {{grouped_matches[0].matchtype}} </span>
                          </router-link>
                      </h2>
                  </div>

                  <div class="cd_cr" v-for="match in grouped_matches" :key="match.id">
                      <div class="cd_cr_lft">

                          <router-link v-if="match.badgeStatus=='Scheduled' || match.badgeStatus == 'Cancelled'" :to="{ name: 'matchDetail.info', params: { slug: match.slug, id: match.id } }" class="wrap-link-all" aria-label="scorecard"></router-link>
                          <router-link v-else :to="{ name: 'matchDetail.scorecard', params: { slug: match.slug, id: match.id } }" class="wrap-link-all" aria-label="scorecard"></router-link>
                          <span v-if="match.badgeStatus == 'Scheduled'" class="cus-tag tag-gray">Not Started Yet</span>
                          <span v-else-if="match.badgeStatus == 'Live' || match.badgeStatus == 'Stumps' || match.badgeStatus == 'Innings break'" class="cus-tag tag-green">LIVE</span>
                          <span v-else-if="match.badgeStatus == 'Finished'" class="cus-tag tag-orange">Finished</span>
                          <span v-else class="cus-tag tag-navy">{{match.badgeStatus}}</span>
                          <span class="date">{{match.matchtype}}</span>
                          <span class="match-count">{{match.matchno}}</span>
                      </div>
                      <!-- {{match}} -->
                      <div class="cd_cr_cnt">
                          <ul class="mh-inf">
                              <li class="active">
                                  <span class="team-info">

                                      <span class="flag" v-if="match.team1" style="top: 47%;"> <img :src="$storageURL+`images/cricket/round/${match.team1.id}.webp`" :alt="match.team1.code" width="34" height="34" loading="lazy" :onerror="$teamDefaultLogo()"> </span>
                                      <span class="team-name" v-if="match.team1 && match.team1.name">{{match.team1.name}}</span>
                                  </span>
                                  <span class="score" v-if="match.badgeStatus != 'Scheduled'">
                                    {{match.ht_score}}
                                    <span class="ovr" v-for="inn in match.innings" :key="inn.order">
                                      <template v-if="inn.team_id == match.team1.id && inn.order == 1 && match.innings.length < 3">
                                        {{ '('+inn.overs+' Ov)' }}
                                        </template>
                                      <template v-if="inn.team_id == match.team1.id && inn.order == 2 && match.innings.length < 3">
                                        {{ '('+inn.overs+' Ov)' }}
                                        </template>
                                      <template v-if="inn.team_id == match.team1.id && inn.order == 3  && match.innings.length > 2">
                                        {{ '('+inn.overs+' Ov)' }}
                                        </template>
                                      <template v-if="inn.team_id == match.team1.id && inn.order == 4 && match.innings.length > 2">
                                        {{ '('+inn.overs+' Ov)' }}
                                        </template>
                                    </span>
                                  </span>
                              </li>
                              <li>
                                  <span class="team-info">
                                      <span class="flag" v-if="match.team2" style="top: 47%;"> <img :src="$storageURL+`images/cricket/round/${match.team2.id}.webp`" :alt="match.team2.code" width="34" height="34" loading="lazy" :onerror="$teamDefaultLogo()"> </span>
                                      <span class="team-name" v-if="match.team2 && match.team2.name">{{match.team2.name}}</span>
                                  </span>
                                  <span class="score" v-if="match.badgeStatus != 'Scheduled'">
                                    {{match.at_score}}
                                      <span class="ovr" v-for="inn in match.innings" :key="inn.order">
                                        <template v-if="inn.team_id == match.team2.id && inn.order == 1 && match.innings.length < 3">
                                          {{ '('+inn.overs+' Ov)' }}
                                          </template>
                                        <template v-if="inn.team_id == match.team2.id && inn.order == 2 && match.innings.length < 3">
                                          {{ '('+inn.overs+' Ov)' }}
                                          </template>
                                        <template v-if="inn.team_id == match.team2.id && inn.order == 3 && match.innings.length > 2">
                                          {{ '('+inn.overs+' Ov)' }}
                                          </template>
                                        <template v-if="inn.team_id == match.team2.id && inn.order == 4 && match.innings.length > 2">
                                          {{ '('+inn.overs+' Ov)' }}
                                          </template>

                                      </span>
                                    </span>
                              </li>
                          </ul>
                          <div class="match-sts">
                              <p v-if="match.comment">{{match.comment}}</p>
                              <!-- <p v-else-if="match.badgeStatus == 'Scheduled' && !match.comment" class="text-dark">{{match.gmt_datetime | formatDate('HH:mm')}}</p> -->
                              <p v-else-if="match.badgeStatus == 'Scheduled' && !match.comment" class="text-dark">{{match.gmt_datetime | cricDateTimeTz('HH:mm')}}</p>
                          </div>
                      </div>
                      <div class="cd_cr_rgt">
                          <ul>
                              <li><span class="star" :class="($store.getters.isMatchFavourited(match.slug)) ? 'active' : '1'" @click="toggleFavourite(match.slug)" href="javascript:;" ></span></li>
                              <li><span class="graph"></span></li>
                          </ul>
                      </div>
                  </div>
        </div>
    <!-- </div> -->
</template>

<script>
    export default {
        name: "match-card",
        props: ['grouped_matches','_key'],
        data() {
            return {}
        },
        mounted() {},
        methods: {
            overs(team) {
                if (!this.match[team])
                    return ''
                let innings = this.match.innings.filter(inning => inning.team_id == this.match[team].id)
                return innings.length ? `(${innings[innings.length - 1].overs} ${this.match.matchtype == '100 Ball Match'? this.$t('cric.Balls') : this.$t('cric.Overs')})` : ''
            },
            isLive() {
                return this.match.badgeStatus == 'Live'
            },

            isFinished() {
                return this.match.badgeStatus == 'Finished'
            },

            isStumps() {
                return this.match.badgeStatus == 'Stumps'
            },

            isCancelled() {
                return this.match.badgeStatus == 'Cancelled'
            },

            isScheduled() {
                return this.match.badgeStatus == 'Scheduled'
            },
            toggleFavourite(matchSlug) {
                this.$store.commit(this.$store.getters.isMatchFavourited(matchSlug) ? 'removeFavouriteMatch' : 'addFavouriteMatch', matchSlug);
                this.$emit('toggleFavourite', matchSlug);
            },
        }
    }
</script>
