<template>
    <div class="container prefix-live-center mt-4">
        <div class="all_matches_content">
          <div class="sabc_lc_tabs_date">
              <ListingTabs :key="$route.params.date+'-tabs'"></ListingTabs>
          </div>
            <div class="match_full_detail">
                <div class="pg-two-columns">

                    <left-sidebar></left-sidebar>

                    <div class="text-center" v-if="!is_data_ready">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else-if="matches.length == 0 && is_data_ready">
                        {{ $t("No match available") }}
                    </div>
                    <div class="pg-content" v-else>
                        <div class="card-cricket-heading">
                            <li>
                                <span style="color: #0c2251; font-size: 22px"> {{ getTournamentName() }}  </span>
                            </li>
                        </div>
                        <match-card class="cd_cr_list" v-for="(grouped_matches, _key) in matches" :key="_key" :grouped_matches="grouped_matches" :_key="_key" v-on:toggleFavourite="removeFavorite($event, _key)"></match-card>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LeftSidebar from'./Common/left-sidebar.vue';
    import MatchCard from './match-card.vue';
    import ListingTabs from './Common/match-listing-tabs';
    export default {
      name: "tournament-matches",
      data() {
          return {
              matches: [],
              heading: ''
          }
      },
      components: {
          LeftSidebar,
          MatchCard,
          ListingTabs
      },
      watch: {
          '$route.params.slug'() {
              this.getTournamentMatches();
          }
      },
      mounted() {
          this.getTournamentMatches();
      },
      serverPrefetch() {
          return this.getTournamentMatches();
      },
      methods: {
          getTournamentMatches() {
            let params = {slug: this.$route.params.slug};
            this.is_data_ready = false;
            this.heading = this.$route.params.slug;
              return this.$axios
                  .get(this.$GET_TOURNAMENT_MATCHES, {params})
                  .then(response => {
                      this.matches = response.data.result.matches;
                      this.is_data_ready = true;
                      this.heading = response.data.result.league;
                      console.log(response.data);
                  });
          },
          getTournamentName() {
            //   let split = this.$route.params.slug.split('-');
            //   return split.slice(0, split.length - 1).join(' ').replace(/^(.)|\s(.)/g, function ($1) {
            //       return $1.toUpperCase();
            //   });
            return this.heading;
          },
          removeFavorite(slug,tour_key) {
                if (this.$route.name == 'myMatches') {
                    var match_list = this.matches[tour_key];
                    this.matches[tour_key].forEach(function (val, key) {
                        if (val.slug == slug) {
                            match_list.splice(key, 1);
                        }
                    });
                }
            }
      },
    }

</script>
<style lang="scss">
@import '~@/assets/scss/cricket.scss';
</style>