<template>
    <div class="pg-sidebar">
        <div class="pg-sidebar-inner">
            <div class="pg-sidebar-box">
                <strong class="box-sidebar-title" style="color: #0c2251">Latest Competitions</strong>
                <div class="sidebar-box-inner">
                    <ul class="sidebar-list">
                        <li v-for="tournament in tournaments" :key="tournament.id" :class="{'active': ($route.params.slug == tournament.slug) }">
                            <router-link :to="{name: 'tournament_matches', params: {slug: tournament.slug+'-'+tournament.id} }"> {{tournament.name}} <strong v-if="tournament.matchType"> - {{tournament.matchType}} </strong> </router-link>
                        </li>
                    </ul>
                </div>
            </div>
      <!-- <div class="pg-sidebar-box">
                  <strong class="box-sidebar-title">Countries</strong>
                  <div class="sidebar-box-inner">
                      <ul class="countries-accordion">
                          <li class="active">
                              <a href="#" class="ac-opener">
                                  <span class="icon-wrap"><img src="https://api.planetcricket.com/resizeImage.php?src=https://api.planetcricket.com/storage/country_flags/icc.png&amp;w=36&amp;h=36&amp;zc=1&amp;q=90&amp;s=1" alt="ICC" width="26" height="26"></span>
                                  <span class="title">ICC</span>
                              </a>
                              <ul class="sidebar-list">
                                  <li><a href="#">ICC Events - T20I</a></li>
                                  <li><a href="#">ICC Events - T20I</a></li>
                                  <li><a href="#">ICC Events - T20I</a></li>
                              </ul>
                          </li>
                          <li>
                              <a href="#" class="ac-opener">
                                  <span class="icon-wrap"><img src="https://api.planetcricket.com/resizeImage.php?src=https://api.planetcricket.com/storage/country_flags/icc.png&amp;w=36&amp;h=36&amp;zc=1&amp;q=90&amp;s=1" alt="ICC" width="26" height="26"></span>
                                  <span class="title">ICC</span>
                              </a>
                              <ul class="sidebar-list">
                                  <li><a href="#">ICC Events - T20I</a></li>
                                  <li><a href="#">ICC Events - T20I</a></li>
                                  <li><a href="#">ICC Events - T20I</a></li>
                              </ul>
                          </li>
                          <li>
                              <a href="#" class="ac-opener">
                                  <span class="icon-wrap"><img src="https://api.planetcricket.com/resizeImage.php?src=https://api.planetcricket.com/storage/country_flags/icc.png&amp;w=36&amp;h=36&amp;zc=1&amp;q=90&amp;s=1" alt="ICC" width="26" height="26"></span>
                                  <span class="title">ICC</span>
                              </a>
                              <ul class="sidebar-list">
                                  <li><a href="#">ICC Events - T20I</a></li>
                                  <li><a href="#">ICC Events - T20I</a></li>
                                  <li><a href="#">ICC Events - T20I</a></li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </div> -->
        </div>
    </div>
</template>

<script>

    export default {
        name: 'left-sidebar',
        data() {
            return {
                tournaments: [],
            }
        },
        mounted() {
            this.getTournaments();
        },
        serverPrefetch() {
            return this.getTournaments();
        },
        methods: {
            getTournaments() {
                return this.$axios
                .get(this.$GET_TOURNAMENTS)
                .then(response => {
                    this.tournaments = response.data.result.tournaments;
                });
            }
        }
    }

</script>
<style scoped>
    .active {
        background-color: #0c2251;
        border-radius: 5px;
        padding-right: 5px;
    }
    .active a {
        color: white !important;
    }
</style>
